import { Layer } from 'react-konva'
import { memo, useMemo } from 'react'
import { AnimatedPoint } from './Point'
import { PointType } from '@/api/services/layer.service'
import { useMapStore } from '@/stores/mapStore'
import { MetablockNodes } from '@/api/services/project.service'
import { LayerBookingItem } from '@/api/services/booking.service'

interface PointsLayerProps {
	colors?: any
	userDepartmentFieldId?: string | null
	userFulltimeFieldId?: string | null
	options?: {
		labelSize: number
		fontSize: number
		color: string
		borderWidth: number
		wrapText: boolean
	}
	points?: PointType[]
	nodes?: MetablockNodes
	bookings?: LayerBookingItem[]
	url: string
	sourceType: 'anonymous' | 'use-credentials'
}

const defaultOptions = {
	labelSize: 0.015,
	fontSize: 12,
	color: '#ffffff',
	borderWidth: 0.1,
	wrapText: false,
}

const getBookable = (pluginData) => {
	let isBookable = false

	Object.values(pluginData).forEach((obj: any) => {
		const hasProp = Object.keys(obj).includes('bookable')

		if (hasProp) {
			isBookable = obj['bookable']
		}
	})

	return isBookable
}

const PointsLayer: React.FC<PointsLayerProps> = ({
	userDepartmentFieldId,
	userFulltimeFieldId,
	colors,
	nodes = {},
	options = defaultOptions,
	bookings = [],
	points = [],
	url,
	sourceType,
}) => {
	const [width, height] = useMapStore((state) => state.size)
	const layers = useMapStore((state) => state.layers)
	const isBookableVisible = useMemo(() => layers['bookable'], [layers])
	const isNotBookableVisible = useMemo(() => layers['not-bookable'], [layers])

	const displayPoints = useMemo(() => {
		return points.filter((point) => {
			const isBookable = getBookable(point.plugin_data)

			if (isBookableVisible && isBookable) return true
			return isNotBookableVisible && !isBookable
		})
	}, [isBookableVisible, isNotBookableVisible, points])

	if (width == 0 || height == 0) return null

	return (
		<Layer>
			{displayPoints.map((point) => (
				<AnimatedPoint
					colors={colors}
					key={point.id}
					id={point.id}
					point={point}
					options={options}
					nodes={nodes}
					bookings={bookings}
					userDepartmentFieldId={userDepartmentFieldId}
					userFulltimeFieldId={userFulltimeFieldId}
					url={url}
					sourceType={sourceType}
				/>
			))}
		</Layer>
	)
}

export default memo(PointsLayer)

// PointsLayer.whyDidYouRender = true
