import { create } from 'zustand'
import { startOfWeek } from 'date-fns'

type BookingState = {
    week: Date
    offset: number
    setWeek: (week: Date) => void
}

export const useBookingStore = create<BookingState>(set => ({
    week: startOfWeek(new Date(), { weekStartsOn: 1 }),
    offset: new Date().getTimezoneOffset() / 30,
    setWeek: (week) => set(() => ({ week }))
}))
