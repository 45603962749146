import { create } from 'zustand'

type LayerType = {
    [key: string]: boolean
}

type OptionType = 'load' | 'basic'

type MapState = {
    initialized: boolean
    layers: LayerType
    option: OptionType
    isLoading: boolean
    size: [number, number]
    stage: any
    initialScale: number
    zoomSeat: number | null
    polygon: number | null
    mapLayer: number | null
    tooltip: string | null
    tooltipPoint: {
        x: number
        y: number
    }
    setOption: (option: OptionType) => void
    setLayers: (layers: LayerType) => void
    updateDepartments: (departments: any) => void
    setTooltip: (seat: string | null) => void
    setTooltipPoint: (tooltipPoint: { x: number, y: number }) => void
    setInitialized: (initialized: boolean) => void
    setMapLayer: (mapLayer: number | null) => void
    setIsLoading: (isLoading: boolean) => void
    setSize: (size: [number, number]) => void
    setInitialScale: (initialScale: number) => void
    setStage: (stage) => void
    setZoomSeat: (seat: number | null) => void
    setPolygon: (polygon: number | null) => void
}

export const useMapStore = create<MapState>(set => ({
    initialized: false,
    layers: {
        // ['layers', 'not-bookable', 'bookable', 'employees']
        layers: true,
        'not-bookable': true,
        'no-departments': true,
        bookable: true,
        employees: true,
    },
    option: 'basic',
    isLoading: false,
    size: [0, 0],
    stage: null,
    initialScale: 1,
    zoomSeat: null,
    polygon: null,
    mapLayer: null,
    tooltip: null,
    tooltipPoint: {
        x: 0,
        y: 0,
    },
    setOption: (option: OptionType) => set(() => ({ option })),
    setLayers: (layers: LayerType) => set(() => ({ layers })),
    updateDepartments: (departments: string[]) => set(({ layers }) => {
        const newLayers = layers

        departments.forEach(dep => {
            if (!newLayers.hasOwnProperty('departments.' + dep)) {
                newLayers['departments.' + dep] = true
            }
        })

        return { layers: newLayers }
    }),
    setTooltip: (tooltip) => set(() => ({ tooltip })),
    setTooltipPoint: (tooltipPoint) => set(() => ({ tooltipPoint })),
    setInitialized: (initialized) => set(() => ({ initialized })),
    setMapLayer: (mapLayer) => set(() => ({ mapLayer })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    setSize: (size) => set(() => ({ size })),
    setInitialScale: (initialScale) => set(() => ({ initialScale })),
    setStage: (stage) => set(() => ({ stage, initialized: true })),
    setZoomSeat: (zoomSeat) => set(() => ({ zoomSeat })),
    setPolygon: (polygon) => set(() => ({ polygon })),
}))

type MapPointType = {
    x: number
    y: number
}

interface OnRescaleProps {
    scale: number
    point: MapPointType
}