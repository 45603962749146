import { createGlobalStyle, css } from 'styled-components'

import OpenSansBoldWoff from '../../assets/fonts/open-sans/OpenSans-Bold.woff'
import OpenSansSemiBoldWoff from '../../assets/fonts/open-sans/OpenSans-SemiBold.woff'
import OpenSansBoldTtf from '../../assets/fonts/open-sans/OpenSans-Bold.ttf'
import OpenSansLightTtf from '../../assets/fonts/open-sans/OpenSans-Light.ttf'
import OpenSansLightWoff from '../../assets/fonts/open-sans/OpenSans-Light.woff'
import OpenSansRegularTtf from '../../assets/fonts/open-sans/OpenSans-Regular.ttf'
import OpenSansRegularWoff from '../../assets/fonts/open-sans/OpenSans-Regular.woff'
import OpenSansSemiBoldTtf from '../../assets/fonts/open-sans/OpenSans-SemiBold.ttf'

import RobotoBoldTtf from '../../assets/fonts/roboto/Roboto-Bold.ttf'
import RobotoBoldWoff from '../../assets/fonts/roboto/Roboto-Bold.woff'
import RobotoLightTtf from '../../assets/fonts/roboto/Roboto-Light.ttf'
import RobotoLightWoff from '../../assets/fonts/roboto/Roboto-Light.woff'
import RobotoMediumTtf from '../../assets/fonts/roboto/Roboto-Medium.ttf'
import RobotoMediumWoff from '../../assets/fonts/roboto/Roboto-Medium.woff'
import RobotoRegularTtf from '../../assets/fonts/roboto/Roboto-Regular.ttf'
import RobotoRegularWoff from '../../assets/fonts/roboto/Roboto-Regular.woff'

import NunitoBold from '../../assets/fonts/nunito/Nunito-Bold.ttf'
import NunitoLight from '../../assets/fonts/nunito/Nunito-Light.ttf'
import NunitoMedium from '../../assets/fonts/nunito/Nunito-Medium.ttf'
import NunitoRegular from '../../assets/fonts/nunito/Nunito-Regular.ttf'

export const globalStyles = css`
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 300;
		src:
			url('${OpenSansLightTtf}') format('ttf'),
			url('${OpenSansLightWoff}') format('woff');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: normal;
		src:
			url('${OpenSansRegularTtf}') format('ttf'),
			url('${OpenSansRegularWoff}') format('woff');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 500;
		src:
			url('${OpenSansSemiBoldTtf}') format('ttf'),
			url('${OpenSansSemiBoldWoff}') format('woff');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		src:
			url('${OpenSansBoldTtf}') format('ttf'),
			url('${OpenSansBoldWoff}') format('woff');
	}

	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 300;
		src:
			url('${RobotoLightTtf}') format('ttf'),
			url('${RobotoLightWoff}') format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: normal;
		src:
			url('${RobotoRegularTtf}') format('ttf'),
			url('${RobotoRegularWoff}') format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		src:
			url('${RobotoMediumTtf}') format('ttf'),
			url('${RobotoMediumWoff}') format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		src:
			url('${RobotoBoldTtf}') format('ttf'),
			url('${RobotoBoldWoff}') format('woff');
	}

	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 300;
		src: url('${NunitoLight}') format('ttf');
	}
	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: normal;
		src: url('${NunitoRegular}') format('ttf');
	}
	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 500;
		src: url('${NunitoMedium}') format('ttf');
	}
	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 700;
		src: url('${NunitoBold}') format('ttf');
	}

	:root {
		--app-height: 100%;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	* {
		min-width: 0;
		min-height: 0;
	}

	html {
		font-size: 62.5%;
	}

	html,
	body,
	#root {
		width: 100vw;
		/* min-width: 100vh; */
		height: 100vh;
		overflow: hidden;
	}

	#root {
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}

	body {
		/* font-family: "Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif; */
		font-family: 'Open Sans', 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial,
			sans-serif;
		width: 100vw;
		height: 100vh;
		font-size: 1.6rem;
		overflow: hidden;
	}

	html,
	body {
		overscroll-behavior-y: none;
	}
	body {
		position: fixed;
		overflow: hidden;
	}

	html,
	body,
	#root,
	#modals {
		padding: 0;
		margin: 0;
		overflow: hidden;
		width: 100vw;
		height: 100vh;
		height: var(--app-height);
	}
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles
