import { SectionType } from '@/containers/SidebarContainer'
import { create } from 'zustand'
import { endOfDay, startOfDay } from 'date-fns'

type GlobalState = {
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
  project: {
    projectId: number
    workspaceId: number
  }
  setProject: (project) => void
  activeLayer: number | null
  setActiveLayer: (layer: number | null) => void
  seat: number | null
  setSeat: (seat: number | null) => void
  seatEmployee: number | null
  setSeatEmployee: (seat: number | null) => void
  booking: number | string | null
  setBooking: (booking: number | string | null) => void
  selector: SectionType | null
  setSelector: (selector: SectionType | null) => void
  employee: number | null
  setEmployee: (seat: number | null) => void
  layerModal: number | null
  popupLayer: number | null
  setLayerModal: (layer: number | null) => void
  setPopupLayer: (popupLayer: number | null) => void
  selection: {
    startDate: Date
    endDate: Date
    key: 'selection'
  }
  setSelection: (start: Date, end: Date) => void
}

export const useGlobalStore = create<GlobalState>((set) => ({
  isLoading: true,
  setLoading: (isLoading) => set(() => ({ isLoading })),
  project: {
    projectId: 0,
    workspaceId: 0
  },
  setProject: (project) => set(() => ({ project })),
  activeLayer: null,
  setActiveLayer: (activeLayer) => set(() => ({ activeLayer })),
  seat: null,
  setSeat: (seat) =>
    set(() => ({ seat, selector: null, employee: null, layerModal: null })),
  seatEmployee: null,
  setSeatEmployee: (seatEmployee) =>
    set(() => ({
      seatEmployee,
      selector: null,
      employee: null,
      layerModal: null
    })),
  booking: null,
  setBooking: (booking) => set(() => ({ booking })),
  selector: null,
  setSelector: (selector) =>
    set(() => ({ selector, seat: null, employee: null, layerModal: null })),
  employee: null,
  setEmployee: (employee) =>
    set(() => ({ employee, selector: null, seat: null, layerModal: null })),
  layerModal: null,
  popupLayer: null,
  setLayerModal: (layerModal) =>
    set(() => ({ layerModal, selector: null, seat: null, employee: null })),
  setPopupLayer: (popupLayer) =>
    set(() => ({
      popupLayer,
      layerModal: null,
      selector: null,
      seat: null,
      employee: null
    })),
  selection: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection'
  },
  setSelection: (startDate: Date, endDate: Date) =>
    set(() => ({
      selection: {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      }
    }))
}))
